<template>
  <basic-container>
    <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.plat.authority.role.title.indexHeadTitle')"
      @head-add="getNew" @head-romve="headRomve">
    </head-layout>
    <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
      @grid-head-search="searchChange" @grid-head-empty="searchReset">
    </grid-head-layout>
    <grid-layout ref="gridLayOut" :tableOptions="option" :tableData="data" :table-loading="loading"
      :data-total="page.total" :page="page" @gird-handle-select-click="selectionChange" @page-size-change="onLoad"
      @page-current-change="onLoad" @page-refresh-change="onLoad" :gridRowBtn="gridRowBtn" @grid-edit="rowUpdate"
      @grid-romve="rowDel" @head-permissions="handleRole" @selectUserList="selectUserList"></grid-layout>
    <el-drawer class="el-drawer__wrapper avue-dialog avue-crud__dialog" :visible.sync="isShow" size="70%"
      style="margin-top: 50px;" :show-close="false">
      <template slot="title">
        <dialog-head-btn @dialog-head-save-click="save" @dialog-head-cancel-click="cancel" head-title="" icon="">
        </dialog-head-btn>
      </template>
      <form-layout v-if="isShow" :column="option.column" :dataForm="dataObj" ref="formLayout"></form-layout>
    </el-drawer>
    <!-- <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form"
      :permission="permissionList" :before-open="beforeOpen" @row-del="rowDel" @row-update="rowUpdate"
      @row-save="rowSave" @search-change="searchChange" @search-reset="searchReset" @selection-change="selectionChange"
      @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange" @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger" size="small" icon="el-icon-delete" v-if="permission.role_delete" plain
          @click="handleDelete">删 除
        </el-button>
        <el-button size="small" icon="el-icon-setting" @click="handleRole" v-if="userInfo.role_name.includes('administrator')"
          plain>权限设置
        </el-button>
      </template>
    </avue-crud> -->
    <el-dialog :title="$t('cip.plat.authority.role.field.rolePermissions')" append-to-body :visible.sync="box"
      v-if="box" width="500px" style="overflow-y: hidden;">
      <el-tabs type="border-card">
        <el-tab-pane :label="$t('cip.plat.authority.role.field.webMenumissions')">
          <el-tree class="el-tree_class" style="height: 50vh;" :data="webMenuGrantList" :show-checkbox="true"
            node-key="id" ref="webTreeMenu" :default-checked-keys="menuTreeObj" :default-expanded-keys="menuTreeObj"
            :props="props" @check-change="handleCheckChange">
          </el-tree>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.authority.role.field.appMenumissions')">
          <el-tree class="el-tree_class" :data="appMenuGrantList" show-checkbox node-key="id" ref="appTreeMenu"
            :default-checked-keys="menuTreeObj" :default-expanded-keys="menuTreeObj" :props="props">
          </el-tree>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.authority.role.field.dataPermissions')">
          <el-tree class="el-tree_class" :data="dataScopeGrantList" show-checkbox node-key="id" ref="treeDataScope"
            :default-checked-keys="dataScopeTreeObj" :default-expanded-keys="dataScopeTreeObj" :props="props">
          </el-tree>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.plat.authority.role.field.protPermissions')">
          <el-tree class="el-tree_class" :data="apiScopeGrantList" show-checkbox node-key="id" ref="treeApiScope"
            :default-checked-keys="apiScopeTreeObj" :default-expanded-keys="apiScopeTreeObj" :props="props">
          </el-tree>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="box = false">{{ $t('cip.cmn.btn.celBtn') }}</el-button>
        <el-button type="primary" @click="submit">{{ $t('cip.cmn.btn.defBtn') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('userinfo.selectionUserInfo')" :visible.sync="showUserDialog" v-if="showUserDialog"
      width="70vw">
      <role-user-dialog @submitUserList="submitUserList" @cancelUserList="cancelUserList" />
    </el-dialog>
    <el-dialog :title="$t('cip.plat.sys.user.field.roleConfig')" append-to-body :visible.sync="roleBox" width="90%"
      top="5vh" :height="dialogHeight">
      <departmentPersonnel ref="departmentPersonnel" :currentRole="currentRole" v-if="roleBox"></departmentPersonnel>
    </el-dialog>
  </basic-container>
</template>

<script>
import { add, getList, getRole, getRoleTreeById, grant, grantTree, remove, update } from "@/api/system/role";
import { getSysConfig, grantMultiDept, grantMultiDeptByRole, setUserConfig } from "@/api/system/user";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import roleUserDialog from '@/views/system/roleUserDialog'
import departmentPersonnel from '@/views/system/departmentPersonnel'
import { getRoleTree } from "@/api/system/role";
import { getDeptTree, selectChildren } from "@/api/system/dept";

let deptListInput = {}
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
    roleUserDialog,
    departmentPersonnel
  },
  props: {
    classification: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      rowId: '',
      roleBox: false,
      roleGrantList: [],
      currentRole: {},
      showUserDialog: false,
      orgObj: {},
      minHeight: 300,
      isShow: false,
      dataObj: '',
      dataTotal: '',
      tenantStatus: "",
      searchForm: {},
      searchColumns: [
        {
          label: "",
          prop: "roleName",
          type: "input",
          span: 4,
          search: true,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleName"),
        },
        // {
        //   label: "",
        //   prop: "tenantId",
        //   type: "select",
        //   span:4,
        //   placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.tenantId"),
        //   dicUrl: "/api/sinoma-system/tenant/select",
        //   addDisplay: false,
        //   editDisplay: false,
        //   viewDisplay: website.tenantMode,
        //   props: {
        //     label: "tenantName",
        //     value: "tenantId"
        //   },
        //   hide: !website.tenantMode,
        //   search: website.tenantMode,
        //
        // },
        {
          label: "",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleAlias"),
          prop: "roleAlias",
          type: "input",
          span: 4,
          search: true,
        },
      ],
      form: {},
      box: false,
      props: {
        label: "title",
        value: "key"
      },
      webMenuGrantList: [],
      appMenuGrantList: [],
      dataScopeGrantList: [],
      apiScopeGrantList: [],
      apiGrantList: [],
      menuTreeObj: [],
      dataScopeTreeObj: [],
      apiScopeTreeObj: [],
      selectionList: [],
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        menuWidth: 300,
        index: true,
        selection: true,
        menu: this.classification != "classification",
        selectable: (row) => {
          return row.id != "1123598816738675201";
        },
        viewBtn: false,
        dialogWidth: 900,
        dialogClickModal: false,
        column: [
          {
            label: this.$t("cip.plat.authority.role.field.roleName"),
            prop: "roleName",
            search: true,
            align: 'left',
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleName"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.authority.role.field.tenantId"),
            prop: "tenantId",
            type: "select",
            dicUrl: "/api/sinoma-system/tenant/select",
            addDisplay: false,
            editDisplay: false,
            viewDisplay: website.tenantMode,
            align: 'left',
            overHidden: true,
            props: {
              label: "tenantName",
              value: "tenantId"
            },
            hide: !website.tenantMode,
            search: website.tenantMode,
            rules: [{
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.tenantId"),
              trigger: "click"
            }]
          },
          {
            label: this.$t("cip.plat.authority.role.field.roleAlias"),
            prop: "roleAlias",
            search: true,
            align: 'left',
            overHidden: true,
            width: 600,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.roleAlias"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.authority.role.field.roleType"),
            type: "select",
            align: 'center',
            hide: true,
            overHidden: true,
            width: 300,
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            prop: "roleType",
          },
          {
            label: this.$t("cip.plat.authority.role.field.parentId"),
            prop: "parentId",
            dicData: [],
            type: "tree",
            hide: true,
            overHidden: true,
            width: 300,
            props: {
              label: "title"
            },
            rules: [
              {
                required: false,
                message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.role.field.parentId"),
                trigger: "click"
              }
            ]
          },
          {
            label: this.$t("cip.plat.authority.role.field.sort"),
            prop: "sort",
            type: "number",
            align: 'right',
            overHidden: true,
            width: 80,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.role.field.sort"),
                trigger: "blur"
              }
            ]
          }
        ]
      },
      data: []
    };
  },
  computed: {
    group() {
      return [
        {
          column: [
            {
              label: this.$t("cip.plat.sys.user.field.dept"),
              prop: "deptId",
              span: 24,
              type: "tree",
              multiple: true,
              dicData: this.deptDicData,
              props: {
                label: "title"
              },
              checkStrictly: true,
              slot: true,
              change(row) {
                deptListInput = row
                console.log("进行输出--------------", deptListInput)

              },
            },
          ]
        },
      ]
    },
    dialogHeight() {
      const windowHeight = window.innerHeight
      const titleHeight = 50 // 标题高度
      const padding = 80 // 内边距
      let height = windowHeight - titleHeight - padding
      if (height < this.minHeight) {
        height = this.minHeight
      }
      console.log("heightheightheight", height)
      return `${height}px`
    },
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.role_add, false),
        viewBtn: this.vaildData(this.permission.role_view, false),
        delBtn: this.vaildData(this.permission.role_delete, false),
        editBtn: this.vaildData(this.permission.role_edit, false)
      };
    },

    headBtnOptions() {
      let buttonBtn = [];
      if (this.permission.role_add && this.classification != "classification") {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            type: "button",
            icon: ""
          });
      }
      if (this.permission.role_delete && this.classification != "classification") {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            icon: ""
          });
      }
      // if (this.permission.role_permissions && this.classification != "classification") {
      //   buttonBtn.push(
      //     {
      //       label: this.$t("cip.cmn.btn.permissionsBtn"),
      //       emit: "head-permissions",
      //       type: "button",
      //       icon: ""
      //     });
      // }
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let buttonBtn = [];
      if (this.permission.role_edit) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.editBtn"),
            emit: "grid-edit",
            type: "text",
            icon: ""
          });
      }
      if (this.permission.role_delete) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "grid-romve",
            type: "text",
            icon: ""
          })
      }
      if (this.permission.role_user) {
        buttonBtn.push(
          {
            label: this.$t("userinfo.userConfig"),
            emit: "selectUserList",
            type: "text",
            icon: ""
          })
      }
      if (this.permission.role_permissions && this.classification != "classification") {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.permissionsBtn"),
            emit: "head-permissions",
            type: "button",
            icon: ""
          });
      }
      return buttonBtn;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);

      });
      return ids.join(",");
    },
    idsArray() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids;
    }
  },
  created() {
    this.getSysConfig()
    this.onLoad(this.page)
    this.initData()
  },
  mounted() {
    console.log("进行输出--------------", this.classification)
  },
  methods: {
    submitUserList(userIds, deptId) {
      this.showUserDialog = false;
      //进行构造数据
      console.log("deptListInput-----", deptListInput)
      // const roleList = this.$refs.treeRole.getCheckedKeys()
      let personnel = [];
      deptListInput.value.forEach((item) => {
        let department = {}
        department = {
          "deptId": item,
          "roleId": this.currentRole.id,
        }
        personnel.push(department)
      })
      let role = {
        "userIds": userIds,
        "roleIds": personnel,
      }
      grantMultiDeptByRole(role).then(res => {
        this.$message({
          type: res.data.success ? "success" : "error",
          message: res.data.msg,
        });
      })
      // console.log("deptIddeptIddeptIddeptId",deptId)
      // setUserConfig(this.currentRole.id, deptId, userIds).then(res=>{
      //   let data =res.data.data
      //   this.$message({
      //     type: res.data.success ? "success" : "error",
      //     message: res.data.msg,
      //   });
      // });
    },
    submitRole() {
      if (deptListInput.value.length == 0) {
        return this.$message({
          type: "error",
          message: this.$t("cip.cmn.msg.success.department")
        });
      }
      this.roleBox = false
      this.showUserDialog = true;
    },
    selectUserList(row) {

      this.currentRole = row;
      // getDeptTree().then(res => {
      //   this.roleGrantList = res.data.data;
      this.roleBox = true;
      // });
    },
    cancelUserList() {
      this.currentRole = {};
      this.showUserDialog = false;
    },
    getSysConfig() {
      getSysConfig().then(res => {
        let data = res.data.data
        this.tenantStatus = data.is_tenant;
      })
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },

    cancel() {
      this.isShow = false;
      this.dataObj = ''
    },
    getNew() {
      this.$router.push({
        path: '/role/edit',
        query: {
          type: 'add',
          tenantStatus: this.tenantStatus,
        }
      })
      // console.log(1111)
      // this.isShow = true;

    },
    headRomve() {
      this.handleDelete()
    },
    initData(roleId) {
      getRoleTreeById(roleId).then(res => {
        const column = this.findObject(this.option.column, "parentId");
        column.dicData = res.data.data;
      });
      getDeptTree(website.tenantId).then(res => {
        const column = this.findObject(this.group, "deptId");
        this.deptDicData = res.data.data;
        column.dicData = res.data.data;
      });
    },
    submit() {
      const menuList = [...this.$refs.webTreeMenu.getCheckedKeys(), ...this.$refs.webTreeMenu.getHalfCheckedKeys()];
      const appMenuList = [...this.$refs.appTreeMenu.getCheckedKeys(), ...this.$refs.appTreeMenu.getHalfCheckedKeys()];
      appMenuList.forEach(a => {
        menuList.push(a)
      })
      const dataScopeList = this.$refs.treeDataScope.getCheckedKeys();
      const apiScopeList = this.$refs.treeApiScope.getCheckedKeys();
      let idsArray = [this.rowId]
      grant(idsArray, menuList, dataScopeList, apiScopeList).then(() => {
        this.box = false;
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
        this.onLoad(this.page);
      });
    },
    rowSave(row, done, loading) {
      add(row).then(() => {
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
        // done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdate(row, index, done, loading) {
      let data = encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path: '/role/edit',
        query: {
          type: 'edit',
          id: row.id,
          tenantStatus: this.tenantStatus,
        }
      })
      // console.log(row)
      // this.dataObj = row
      // this.isShow = true;
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },

    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.roleName == '') {
        delete params.roleName
      }
      if (params.roleAlias == '') {
        delete params.roleAlias
      }
      delete params.$tenantId
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      // done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.gridLayOut.selectionClear();
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData(this.form.id);
      }
      // done();
    },
    handleRole(row) {
      // if (this.selectionList.length === 0) {
      //   this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
      //   return;
      // } else if (this.selectionList.length > 1) {
      //   this.$message.warning(this.$t('cip.cmn.msg.warning.selectOneWarning'));
      //   return;
      // }
      this.menuTreeObj = [];
      this.dataScopeTreeObj = [];
      this.apiScopeTreeObj = [];

      grantTree()
        .then(res => {
          this.webMenuGrantList = res.data.data.menu;
          this.appMenuGrantList = res.data.data.appMenu;
          this.dataScopeGrantList = res.data.data.dataScope;
          this.apiScopeGrantList = res.data.data.apiScope;
          this.rowId = row.id
          getRole(row.id).then(res => {
            console.log(res.data.data.menu, "12212")
            this.menuTreeObj = res.data.data.menu;
            let newArr = [];
            let item = "";
            if (this.menuTreeObj && this.menuTreeObj.length !== 0) {
              this.menuTreeObj.forEach(item => {
                this.checked(item, this.webMenuGrantList, newArr);
                this.checked(item, this.appMenuGrantList, newArr);
              });
              this.menuTreeObj = newArr;
            }
            this.dataScopeTreeObj = res.data.data.dataScope;
            this.apiScopeTreeObj = res.data.data.apiScope;
            this.box = true;
          });
        });

    },
    checked(id, data, newArr) {
      data.forEach(item => {
        if (item.id == id) {
          if (!item.hasChildren) {
            newArr.push(item.id);
          }
        } else {
          if (item.hasChildren && item.children.length != 0) {
            this.checked(id, item.children, newArr);
          }
        }
      });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          this.$refs.gridLayOut.selectionClear();
        });
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.page = page
      this.loading = true;
      console.log(page)
      if (this.classification === "classification") {
        this.query.classificationRole = "classificationRole"
      }
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.data = data.records;
        this.$refs.gridLayOut.page.total = data.total;
        this.loading = false;
        this.selectionClear();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__title {
  font-size: 14px;
}

::v-deep .el-tree_class {
  height: 50vh;
  overflow-y: auto;
}
</style>

<template>
  <div>
    <head-layout  :head-title="'数据服务'"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">

    </head-layout>
    <el-container class="new-sino-box">
      <el-header class="new-sino-header">
        <div >
          <el-input placeholder="请输入服务名称" v-model="queryList.dict_code" class="new-sino-input" size="mini" />
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
          <el-button size="mini"   @click="tzFn">服务类别管理</el-button>
        </div>
      </el-header>
      <el-main class="new-sino-main">
        <el-table
          v-tableHe
          size="mini"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          border
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="qry_id"
            label="服务ID"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="qry_name"
            label="服务名称"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="qry_desc"
            label="服务描述"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="class_name"
            label="服务类别"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="qry_type"
            label="调用方式"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button
                size="mini"
                type="text"
                @click="deleteFn(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="float:right;height: 60px;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryList.startIndex"
            :page-size="queryList.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  getDictList,
  delDict
} from "@/api/dataAcquisition/DataAssets";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {queryNameList} from "@/api/dataAcquisition/DataServices";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        perPage:20,
        startIndex:1,
        dict_code:'',
        dict_name:''
      },
      loading:false,
      rules: {
        sourceCode: [
          { required: true, message: '请输入数据源编码', trigger: 'change' }
        ],
        sourceName: [
          { required: true, message: '请输入数据源编码', trigger: 'change' }
        ],
        sourceDesc: [
          { required: true, message: '请输入数据源编码', trigger: 'change' }
        ],
        sourceType: [
          { required: true, message: '请输入数据员类型', trigger: 'change' }
        ],
        databaseName: [
          { required: true, message: '请输入数据库名称', trigger: 'change' }
        ],
        sourceConfig: [
          { required: true, message: '请输入数据源连接配置', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
      },
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      queryNameList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.listFunc
        this.total = obj.total
      });
    },
    addFn(){
      this.$router.push({
        path: '/DataAssets/dataDictionaryAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(index,item){
      this.$router.push({
        path: '/DataAssets/dataDictionaryAdd',
        query: {
          type: 'edit',
          id:item.dict_id
        }
      })
    },
    tzFn(){
      this.$router.push({
        path: '/DataServices/dataServicesType',
      })
    },
    deleteFn(index,item){
      if (index) this.multipleSelection = [item];
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.dict_id
      })
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delDict(ids).then((res)=>{
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList.param1 = '';
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.queryList.perPage = val;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.queryList.startIndex = val;
      this.onLoad()
    },
  },
  created() {
    this.onLoad()
  },
  mounted() {
  },
};
</script>

<style scoped>
::v-deep .el-button+.el-button {
  margin-left: 0px;
}

</style>
